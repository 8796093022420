import React from 'react';
import logo from './logo.svg';
import './App.css';
import HomeIcon from './HomeIcon';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const homePage = <>
  <div className="App" style={{marginBottom:'10rem'}}>
      
      <header className="App-header">
        
        <section>
        <div className="custom-shape-divider-top-1712812628">
          <div className="topnav">
                <a href="/">Home</a>
                <a href="/contact">Contact Us</a>
              </div>
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
            </svg>

            
        </div>
        
          <h1>Clampdown</h1>
          <div className="custom-shape-divider-bottom-1712640273">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                  <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                  <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
              </svg>
          </div>
          <h2 style={{color:'#61dafb'}}>Functional. Flexible. Flawless.</h2>
          
        </section>
        
      </header>
      <section>
      <div className="custom-shape-divider-top-1712813410">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill"></path>
          </svg>
      </div>
          <h1>Our Mission</h1>
          <div className="custom-shape-divider-bottom-1712640273">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                  <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                  <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
              </svg>
          </div>
          
          <h2 style={{color:'#61dafb', fontSize:42, justifyContent:'center'}}>ClampDown was created to solve the ever present problem of organization on the GO</h2>
          
        </section>

        <section style={{backgroundColor:'#89CFF0', paddingBottom:'1rem', color:'#f2f2f2', fontSize:23, justifyContent:'center', margin:'auto'}}>
          <h1 style={{color:'#f2f2f2'}}>Reviews</h1>
          
          <div style={{display:'flex'}}>
            <h2 style={{color:'#f2f2f2', justifyContent:'center', width:'64rem', margin:'auto'}}><span style={{fontSize:55, fontFamily:'serif'}}>"</span>It would help me organize my notes as if I had my computer on the table in front of me<span style={{fontSize:55, fontFamily:'serif'}}>"</span> - Ruth Hall</h2>
            <h2 style={{color:'#f2f2f2', justifyContent:'center', width:'64rem', margin:'auto'}}><span style={{fontSize:55, fontFamily:'serif'}}>"</span>The product seems to be a great idea and something for someone who needed to organize their space more would and should buy<span style={{fontSize:75, fontFamily:'serif'}}>"</span> - Duncan Kennedy</h2>

          </div>
          <div style={{display:'flex'}}>
            <h2 style={{color:'#f2f2f2', justifyContent:'center', width:'64rem', margin:'auto'}}><span style={{fontSize:55, fontFamily:'serif'}}>"</span>As someone who does the majority of their work on laptops and other portable devices this sounds like it could be an excellent addition to my toolset<span style={{fontSize:55, fontFamily:'serif'}}>"</span> - Hayden Karp</h2>
            <h2 style={{color:'#f2f2f2', justifyContent:'center', width:'64rem', margin:'auto'}}><span style={{fontSize:55, fontFamily:'serif'}}>"</span>This product is great idea for college students<span style={{fontSize:55, fontFamily:'serif'}}>"</span> - Blez Arbolado</h2>
          </div>
        </section>

        <section style={{justifyContent:'center'}}>
          <h1>About Us</h1>
          
          <h2 style={{color:'#61dafb', fontSize:42, justifyContent:'center', width:'90%', margin:'auto'}}>ClampDown started with four high school students: Andrew, Jon, Conner, and Logan. (us) Needing to study a lot was a shared problem among us, and that need always brought us to new study locations. From different coffee shops, to the library, to park benches, we studied everywhere. A common problem for us while studying was trying to organize our workspace. This problem made the birth of ClampDown.</h2>
          
        </section>

        <section style={{backgroundColor:'#89CFF0', paddingBottom:'2rem', color:'#f2f2f2', fontSize:23, justifyContent:'center', margin:'auto'}}>
          <h1 style={{color:'#f2f2f2', marginBottom:'1rem'}}>Products</h1>
          
          <h2 style={{color:'#f2f2f2', justifyContent:'center', width:'64rem', margin:'auto'}}>ClampDown, the flexible utility mount and its associated products are coming soon, check back here to get one as soon as they're available!</h2>
          <div style={{display:'flex', justifyContent:'center', margin:'auto'}}>
            <img src="/1.png" style={{width:'250px', height:'300px', marginRight:'20%'}}/>
            <img src="/2.png" style={{width:'250px', height:'300px'}}/>
          </div>
        </section>

        

        
    </div>
</>

const contactPage = <>
  <div className="App" style={{marginBottom:'10rem'}}>
      
      <header className="App-header">
        
        <section>
        <div className="custom-shape-divider-top-1712812628">
          <div className="topnav">
                <a href="/">Home</a>
                <a href="/contact">Contact Us</a>
              </div>
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
            </svg>

            
        </div>
        
          <h1>Contact Us!</h1>
          <div className="custom-shape-divider-bottom-1712640273">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                  <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                  <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
              </svg>
          </div>
          <h2 style={{color:'#61dafb'}}>We'd love to hear from you!</h2>
          
        </section>
        
      </header>
      <section>
      <div className="custom-shape-divider-top-1712813410">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill"></path>
          </svg>
      </div>
          <h1>Find Us Here</h1>
          <div className="custom-shape-divider-bottom-1712640273">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                  <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                  <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
              </svg>
          </div>
          
          <h2 style={{color:'#61dafb', fontSize:42, justifyContent:'center'}}>1-614-556-3788</h2>
          <h2 style={{color:'#61dafb', fontSize:42, justifyContent:'center'}}>clampdownofficial on Facebook</h2>
          <h2 style={{color:'#61dafb', fontSize:42, justifyContent:'center'}}>clampdownofficial on Instagram</h2>
          <h2 style={{color:'#61dafb', fontSize:42, justifyContent:'center'}}>Clampdown! on Youtube</h2>
          <h2 style={{color:'#61dafb', fontSize:42, justifyContent:'center'}}>Clampdown! on Tiktok</h2>
          <h2 style={{color:'#61dafb', fontSize:42, justifyContent:'center'}}>clampdownofficial on X</h2>
          <h2 style={{color:'#61dafb', fontSize:42, justifyContent:'center'}}>CDcommunity on Discord</h2>
          <h2 style={{color:'#61dafb', fontSize:42, justifyContent:'center'}}>clampdownofficial@gmail.com</h2>
          
        </section>
        
    </div>
</>

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={homePage} />
          <Route path="contact" element={contactPage} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
